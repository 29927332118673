// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASE_URL:"http://91.151.89.22:3300/admin",
  BASE_URL2:"http://91.151.89.22:3300/",
  BASE_URL3:"http://91.151.89.22/",
  ONE_SIGNAL_ID:"825629a5-ba80-4ec5-bf55-19971db47318"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
